import React from "react";
import {t} from "i18next";

const SliderTwo = () => {
  return (
    <>
      <div className="slider__item slider__item--second slider__item--open">
        <div className="slider__text-wrapper">
          <h2 className="slider__lead-text big-text">{t('lead.open.preTitle')}</h2>
          <p className="slider__title-text title-text title-text--white" dangerouslySetInnerHTML={
            {__html: t('lead.open.bigText')}} />
        </div>
      </div>
      <div className="slider__item slider__item--second slider__item--ip">
        <div className="slider__text-wrapper">
          <h2 className="slider__lead-text big-text">{t('lead.ip.preTitle')}</h2>
          <p className="slider__title-text title-text title-text--white" dangerouslySetInnerHTML={
            {__html: t('lead.ip.bigText')}} />
        </div>
      </div>
      <div className="slider__item slider__item--second slider__item--creativity">
        <div className="slider__text-wrapper">
          <h2 className="slider__lead-text big-text">{t('lead.creativity.preTitle')}</h2>
          <p className="slider__title-text title-text title-text--white" dangerouslySetInnerHTML={
            {__html: t('lead.creativity.bigText')}} />
        </div>
      </div>
    </>
  );
};

export default SliderTwo;
