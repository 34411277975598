import React from "react";
import {t} from "i18next";

const Slider = () => {
  return (
    <>
      <div className="slider__item slider__item--culture">
        <div className="slider__text-wrapper">
          <h2 className="slider__title-text title-text">{t('lead.culture.preTitle')}</h2>
          <p className="slider__lead-text big-text big-text--black" dangerouslySetInnerHTML={
            {__html: t('lead.culture.bigText')}} />
        </div>
      </div>
      <div className="slider__item slider__item--brand">
        <div className="slider__text-wrapper">
          <h2 className="slider__title-text title-text">{t('lead.brandTarget.preTitle')}</h2>
          <p className="slider__lead-text big-text big-text--black" dangerouslySetInnerHTML={
            {__html: t('lead.brandTarget.bigText')}} />
        </div>
      </div>
    </>
  );
};

export default Slider;
